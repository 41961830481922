<template>
  <div :class="['div-input', className]">
    <div class="display-only" v-if="isDisplay"></div>
    <div :class="['input-custom', { error: isValidate }]">
      <label v-if="textFloat">
        {{ textFloat }}
        <span v-if="isRequired" class="text-danger">*</span>
      </label>
      <div class="input-group">
        <div class="input-group-prepend">
          <b-dropdown
            :id="`dropdown-${id}`"
            :text="dropdownText"
            variant="outline-secondary"
            class="dropdown-input-custom"
          >
            <b-dropdown-item
              v-for="(item, i) in list"
              :key="i"
              @click="handleChangeDropdown(item, i)"
              >{{ item.value }}</b-dropdown-item
            >
          </b-dropdown>
        </div>
        <input
          :class="['custom-input']"
          :type="type == 'password' ? passwordType : type"
          :placeholder="placeholder"
          :name="name"
          :required="required"
          :value="value"
          :size="size"
          @input="$emit('input', $event.target.value)"
          @change="onDataChange"
          @keyup="$emit('onKeyup', $event)"
          @keypress="$emit('onKeypress', $event)"
          :disabled="disabled"
          :maxlength="maxLength"
        />
      </div>
      <div
        v-if="isShowPassword"
        :class="['button-eye', { hastextFloat: textFloat != '' }]"
      >
        <font-awesome-icon
          v-if="passwordType == 'password'"
          @click="handleShowHidePassword"
          :icon="['fas', 'eye-slash']"
          class="pointer"
        />
        <font-awesome-icon
          v-else
          @click="handleShowHidePassword"
          :icon="['fas', 'eye']"
          class="pointer"
        />
      </div>
    </div>
    <img :src="img" alt="logo-lang" v-if="img" class="logo-lang" />
    <span v-if="detail" class="text-desc text-wrap">{{ detail }}</span>
    <div v-if="v && v.$error">
      <span class="text-error" v-if="v.required == false">กรุณากรอกข้อมูล</span>
      <span class="text-error" v-else-if="v.minLength == false"
        >กรุณากรอกตัวอักษรอย่างต่ำ {{ v.$params.minLength.min }} ตัวอักษร
      </span>
      <span class="text-error" v-else-if="v.password == false"
        >รหัสผ่านต้องมีจำนวนไม่น้อยกว่า 8 ตัว
        และต้องประกอบด้วยตัวอักษรภาษาอังกฤษ/ตัวเลข
      </span>
      <span class="text-error" v-else-if="v.email == false"
        >กรุณากรอกอีเมลให้ถูกต้อง (เช่น email@example.com).</span
      >
      <span class="text-error" v-else-if="v.integer == false"
        >กรุณากรอกตัวเลขเท่านั้น
      </span>
      <span class="text-error" v-else-if="v.maxLength == false"
        >กรุณากรอกตัวอักษรมากสุด {{ v.$params.maxLength.max }} ตัวอักษร
      </span>
      <span class="text-error" v-else-if="v.decimal == false"
        >กรุณากรอกตัวเลขเท่านั้น
      </span>
      <span class="text-error" v-else-if="v.minValue == false"
        >กรุณากรอกตัวเลขมากกว่า 0
      </span>
      <span class="text-error" v-else-if="v.maxValue == false"
        >สามารถกรอกตัวเลขได้สูงสุด {{ maxValue }}
      </span>
      <span class="text-error" v-else-if="v.validateMinvalue == false"
        >กรุณากรอกตัวเลขมากกว่า 0
      </span>
      <span class="text-error" v-else-if="v.validateMaxValue == false"
        >สามารถกรอกตัวเลขได้สูงสุด {{ maxValue }}
      </span>
      <span class="text-error d" v-else-if="v.sameAsPassword == false"
        >รหัสผ่านต้องสัมพันธ์กัน
      </span>
      <span class="text-error d" v-else-if="v.url == false"
        >Invalid URL Link (example : https://www.example.com)
      </span>
      <span class="text-error" v-else-if="v.priceAfterDiscount == false"
        >ราคาหลังหักส่วนลดต้องมากกว่าหรือเท่ากับ 0
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    textFloat: {
      required: false,
      type: String,
    },
    placeholder: {
      required: true,
      type: String,
    },
    type: {
      required: true,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
    },
    detail: {
      required: false,
      type: String,
    },
    size: {
      required: false,
      type: String,
    },
    name: {
      required: false,
      type: String,
    },
    maxValue: {
      required: false,
      type: [String, Number],
    },
    value: {
      required: false,
      type: [String, Number],
    },
    isDisplay: {
      required: false,
      type: Boolean,
    },
    isRequired: {
      required: false,
      type: Boolean,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    v: {
      required: false,
      type: Object,
    },
    className: {
      required: false,
      type: String,
    },
    img: {
      required: false,
      type: String,
    },
    disabled: {
      required: false,
      type: Boolean,
    },
    icon: {
      required: false,
      type: String,
    },
    maxLength: {
      required: false,
      type: Number,
    },
    isShowPassword: {
      required: false,
      type: Boolean,
    },
    list: {
      required: false,
      type: Array,
      default: [],
    },
    valueDropdown: {
      required: false,
      type: [Number, String],
    },
    id: {
      required: false,
      type: [Number, String],
    },
  },
  data() {
    return {
      passwordType: "password",
      dropdownText: "+66",
    };
  },
  mounted() {
    if (this.valueDropdown) {
      let filtered = this.list.filter((item) => this.valueDropdown == item.id);
      this.dropdownText = filtered[0].value;
    } else {
      this.handleChangeDropdown(this.list[0], 0);
    }
  },
  methods: {
    onDataChange(event) {
      this.$emit("onDataChange", event.target.value);
    },
    handleShowHidePassword() {
      if (this.passwordType == "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
    handleChangeDropdown(item, i) {
      this.dropdownText = item.value;
      this.$emit("onChangeDropdown", item, i);
    },
  },
};
</script>

<style scoped>
.div-input {
  margin-bottom: 15px;
  position: relative;
  white-space: nowrap;
}
.input-custom {
  padding: 0px;
}
.input-custom label {
  color: #16274a;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
  width: 100%;
}
.input-custom input {
  color: #16274a;
  /* background-color: white; */
  border: 1px solid #bcbcbc;
  border-radius: 0px;
  padding: 5px 10px;
}
.input-custom input[size="lg"] {
  height: 45px;
}
.input-custom input:focus {
  /* border: 1px solid #16274a; */
  border: 1px solid #ffb300;
}
.input-custom.error input {
  border-color: red !important;
}
::-webkit-input-placeholder {
  /* Edge */
  color: rgba(22, 39, 74, 0.4);
}
:-ms-input-placeholder {
  /* Internet Explorer */
  color: rgba(22, 39, 74, 0.4);
}
::placeholder {
  color: rgba(22, 39, 74, 0.4);
}
.custom-input {
  display: block;
  border: none;
  width: 100%;
}
.text-desc {
  color: #9b9b9b;
  font-size: 12px;
}
.display-only {
  position: absolute;
  z-index: 5000;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.text-error {
  color: #ff0000;
  font-size: 14px;
}
.d-grid {
  display: grid;
}
.button-eye {
  position: absolute;
  right: 20px;
  top: 1.1rem;
  z-index: 6;
}
.hastextFloat {
  top: 1.25rem;
}
.dropdown-input-custom button {
  height: 40px;
  padding: 0 10px;
  border: 1px solid #ced4da;
  font-size: 1rem;
}
.dropdown-input-custom ul {
  padding: 0;
}
.dropdown-input-custom ul a {
  padding: 5px 10px;
}
::v-deep .dropdown-input-custom > .dropdown-toggle {
  color: #495057;
  background-color: #fff;
  border-color: #ced4da;
}
.input-group {
  flex-wrap: nowrap !important;
}
@media (max-width: 767.98px) {
  /* .div-input {
    margin-top: 0;
  } */
  .input-custom > label {
    font-size: 15px;
  }
}
</style>
